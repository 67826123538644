import React, { useState, useEffect } from 'react'

import * as OrderAPI from '../OrderAPI'
import * as FragmentedOrderApi from '../FragmentedOrderApi'
import * as ShipmentApi from '../ShipmentApi'
import * as StringUtils from '../../commons/StringUtils'
import useDebounce from '../../commons/components/Debounce'
import { MessageType, addMessage } from '../../commons/MessageUtils'
import { injectTrackSaleWidget } from '../../commons/TrackSaleWidget'
import { STATUS } from '../../commons/Status'
import {
    BASE_BOARD,
    mountBoardData,
    composeFunctions,
    unifiesOrdersAndOrderGroups,
    filterCardsFromLane,
    filterCardsWithSearchTerm,
    mountCards,
    sortCardsByUrgencyAndRequestDate,
    FILTER_TYPE,
} from '../DashboardScreenUtils'
import { useDashboardContext } from '../../context/DashboardContext'
import { useMillContext } from '../../context/MillContext'
import { PromiseWrapper } from '../../commons/PromiseUtils'
import DashboardView from './DashboardView'
import {
    getUserPreferences,
    keyStorage,
    createUserPreferences,
} from '../../commons/LocalStorage'
import { analyticsEvent, EVENT_KEYS } from '../../commons/AnalyticsUtils'

export default function DashboardContainer() {
    const [boardData, setBoardData] = useState(BASE_BOARD)
    const [filteredBoardData, setFilteredBoardData] = useState(BASE_BOARD)
    const [searchTerm, setSearchTerm] = useState('')
    const [showOrderConfirm, setShowOrderConfirm] = useState(false)
    const [showOrderGroupConfirm, setShowOrderGroupConfirm] = useState(false)
    const [cardDetails, setCardDetails] = useState('')
    const [sourceLane, setSourceLane] = useState({})
    const [eventBus, setEventBus] = useState({})
    const [filterType, setFilterType] = useState(FILTER_TYPE.DEFAULT)

    const debouncedSearchTerm = useDebounce(searchTerm, 500)

    const {
        loading,
        orders,
        selectedOrders,
        fragmentedOrders,
        ordersWithFragments,
        selectedFragmentedOrder,
        selectedOrderCard,
        simpleBillings,
        shipments,
        showOrderGroupModal,
        orderGroups,
        setLoading,
        fetchDashboardData,
        setSelectedOrderCard,
        setSelectedFragmentedOrder,
        changeOrderStatus,
        changeFragmentedOrderStatus,
        changeShipmentStatus,
        handleOrderGroupModal,
        createOrderGroupCardOnPreferences,
        removeOrderGroupCardOnPreferences,
    } = useDashboardContext()

    const { mill, millUser } = useMillContext()

    useEffect(() => {
        if (mill && mill.gcpId) fetchDashboardData({ millId: mill.gcpId })
    }, [mill])

    useEffect(() => {
        if (
            Array.isArray(orders) &&
            Array.isArray(orderGroups) &&
            Array.isArray(ordersWithFragments) &&
            Array.isArray(fragmentedOrders) &&
            Array.isArray(shipments) &&
            Array.isArray(simpleBillings)
        ) {
            const processOrders = composeFunctions(
                unifiesOrdersAndOrderGroups,
                filterCardsFromLane,
                mountCards,
                sortCardsByUrgencyAndRequestDate
            )

            const processesCards = lane =>
                processOrders({
                    orders,
                    orderGroups,
                    ordersWithFragments,
                    fragmentedOrders,
                    shipments,
                    simpleBillings,
                    cards: [],
                    lane,
                })

            const boardData = mountBoardData(processesCards)
            setBoardData(boardData)
        }
    }, [
        orders,
        orderGroups,
        ordersWithFragments,
        fragmentedOrders,
        shipments,
        simpleBillings,
    ])
    useEffect(() => {
        createOrderGroupCardOnPreferences()
    }, [createOrderGroupCardOnPreferences])

    useEffect(() => {
        let userPreferences = getUserPreferences()
        if (!userPreferences) {
            createUserPreferences()
            createOrderGroupCardOnPreferences()
            userPreferences = getUserPreferences()
        }
        const orderGroupInStorage = Object.keys(
            userPreferences[keyStorage.orderGroupCard]
        )
        const orderGroupsNumber = orderGroups.map(
            group => group.orderGroupNumber
        )
        const orderGroupInStorageToRemove = orderGroupInStorage.filter(
            number => !orderGroupsNumber.includes(Number(number))
        )
        if (orderGroups.length > 0)
            orderGroupInStorageToRemove.forEach(number =>
                removeOrderGroupCardOnPreferences(number)
            )
    }, [
        orderGroups,
        createOrderGroupCardOnPreferences,
        removeOrderGroupCardOnPreferences,
    ])

    useEffect(() => {
        const searchTerm = StringUtils.normalize(debouncedSearchTerm)

        const processOrdersFilter = composeFunctions(
            filterCardsFromLane,
            filterCardsWithSearchTerm,
            sortCardsByUrgencyAndRequestDate
        )

        const processesCards = lane =>
            processOrdersFilter({
                cards: lane.cards,
                lane,
                searchTerm,
                filterType,
            })

        const filteredBoardData = mountBoardData(processesCards, boardData)
        setFilteredBoardData(filteredBoardData)
    }, [debouncedSearchTerm, boardData, filterType])

    useEffect(() => {
        const userEmail = millUser.user.email || ''

        if (!mill || userEmail.endsWith('@prodap.com.br')) return
        injectTrackSaleWidget(mill, millUser.user)
    }, [mill, millUser])

    const handleDragEnd = (
        cardId,
        sourceLaneId,
        targetLaneId,
        _,
        cardDetails
    ) => {
        if (sourceLaneId === targetLaneId) return

        handleSourceLaneText(sourceLaneId)
        setCardDetails(cardDetails)

        if (sourceLaneId === STATUS.ENTREGUE) {
            if (cardDetails.isOrderGroup) {
                addMessage(
                    MessageType.ERROR,
                    'Esta carga não pode retroceder. A carga já foi enviada ao destino.'
                )
            } else {
                addMessage(
                    MessageType.ERROR,
                    'Este pedido não pode retroceder. O pedido já foi enviado ao destino.'
                )
            }
            return false
        }

        if (targetLaneId === 'ENTREGUE') {
            if (cardDetails.isOrderGroup) {
                setShowOrderGroupConfirm(true)
            } else {
                setShowOrderConfirm(true)
            }
            return false
        }

        if (cardDetails.isFragmentedOrder) {
            handleUpdateFragmentStatus(cardId, sourceLaneId, targetLaneId)
            return
        }

        if (cardDetails.isUseShipment) {
            handleUpdateShipmentStatus(cardId, sourceLaneId, targetLaneId)
            return
        }

        if (cardDetails.isOrderGroup) {
            cardDetails.orders.forEach(order => {
                handleUpdateOrderStatus(order._id, sourceLaneId, targetLaneId)
            })
            return
        }

        handleUpdateOrderStatus(cardId, sourceLaneId, targetLaneId)
    }

    const handleSourceLaneText = sourceLaneId => {
        const { id, description } = BASE_BOARD.lanes.find(
            lane => lane.id === sourceLaneId
        )
        setSourceLane({ id, description })
    }

    const handleButtonMoveClick = (cardDetails, sourceLane) => {
        eventBus.publish({
            type: 'MOVE_CARD',
            fromLaneId: sourceLane.id,
            toLaneId: cardDetails.laneId,
            cardId: cardDetails.id,
            index: 0,
        })

        if (cardDetails.isOrderGroup) {
            setShowOrderGroupConfirm(false)
            addMessage(
                MessageType.SUCCESS,
                `O agrupamento Nº ${cardDetails.orderGroupNumber} teve a situação alterada de "${sourceLane.description}" para a situação "Entregue" com sucesso!`
            )

            cardDetails.orders.forEach(order => {
                handleUpdateOrderStatus(
                    order.id,
                    sourceLane.id,
                    cardDetails.laneId
                )
            })
            return
        }

        if (cardDetails.isFragmentedOrder) {
            setShowOrderConfirm(false)

            addMessage(
                MessageType.SUCCESS,
                `O fracionamento Nº ${cardDetails.orderNumber}/${cardDetails.number} teve a situação alterada de "${sourceLane.description}" para a situação "Entregue" com sucesso!`
            )

            handleUpdateFragmentStatus(
                cardDetails.id,
                sourceLane.id,
                cardDetails.laneId
            )
            return
        }

        if (cardDetails.isUseShipment) {
            setShowOrderConfirm(false)

            addMessage(
                MessageType.SUCCESS,
                `A remessa futura Nº ${cardDetails.orderNumber}/${cardDetails.number} teve a situação alterada de "${sourceLane.description}" para a situação "Entregue" com sucesso!`
            )

            handleUpdateShipmentStatus(
                cardDetails.id,
                sourceLane.id,
                cardDetails.laneId
            )
            return
        }

        setShowOrderConfirm(false)

        addMessage(
            MessageType.SUCCESS,
            `O pedido Nº ${cardDetails.orderNumber} teve a situação alterada de "${sourceLane.description}" para a situação "Entregue" com sucesso!`
        )
        handleUpdateOrderStatus(
            cardDetails.id,
            sourceLane.id,
            cardDetails.laneId
        )
    }

    const handleUpdateOrderStatus = async (orderId, oldStatus, newStatus) => {
        changeOrderStatus(orderId, newStatus)
        PromiseWrapper({
            promise: OrderAPI.updateOrderStatus(orderId, newStatus),
            onRejected: () => {
                changeOrderStatus(orderId, oldStatus)
                addMessage(
                    MessageType.ERROR,
                    'Falha ao atualizar status do pedido.'
                )
            },
        })
    }

    const handleUpdateFragmentStatus = async (
        orderId,
        oldStatus,
        newStatus
    ) => {
        try {
            changeFragmentedOrderStatus(orderId, newStatus)
            const response = await FragmentedOrderApi.updateFragmentedOrder(
                orderId,
                newStatus
            )

            if (!response || !response.data || response.data.message) {
                changeFragmentedOrderStatus(orderId, oldStatus)
                addMessage(
                    MessageType.ERROR,
                    'Falha ao atualizar status do fracionamento de pedido.'
                )
            }
            return
        } catch (err) {
            console.error(err)
            changeFragmentedOrderStatus(orderId, oldStatus)
            addMessage(
                MessageType.ERROR,
                'Falha ao atualizar status do fracionamento de pedido.'
            )
        }
    }

    const handleUpdateShipmentStatus = async (
        orderId,
        oldStatus,
        newStatus
    ) => {
        try {
            changeShipmentStatus(orderId, newStatus)
            const response = await ShipmentApi.updateShipment(
                orderId,
                newStatus
            )
            if (!response || !response.data || response.data.message) {
                changeShipmentStatus(orderId, oldStatus)
                addMessage(
                    MessageType.ERROR,
                    'Falha ao atualizar status da remessa futura.'
                )
            }
            if (newStatus === STATUS.ENTREGUE) {
                const simpleBillingId = response.data.orderId
                const simpleBilling = await OrderAPI.getOrderById(
                    simpleBillingId
                )
                const isDelivered =
                    simpleBilling.order.status === STATUS.ENTREGUE
                isDelivered && analyticsEvent(EVENT_KEYS.deliveredSimpleBilling)
            }
            return
        } catch (err) {
            console.error(err)
            changeShipmentStatus(orderId, oldStatus)
            addMessage(
                MessageType.ERROR,
                'Falha ao atualizar status da remessa futura.'
            )
        }
    }

    const requestUpdatedDashboardData = async gcpId => {
        setLoading(true)
        fetchDashboardData({ millId: gcpId })
    }

    return (
        <DashboardView
            loading={loading}
            selectedOrders={selectedOrders}
            handleOrderGroupModal={handleOrderGroupModal}
            showOrderGroupModal={showOrderGroupModal}
            selectedOrderCard={selectedOrderCard}
            setSelectedOrderCard={setSelectedOrderCard}
            selectedFragmentedOrder={selectedFragmentedOrder}
            setSelectedFragmentedOrder={setSelectedFragmentedOrder}
            filteredBoardData={filteredBoardData}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            setFilterType={setFilterType}
            setEventBus={setEventBus}
            handleDragEnd={handleDragEnd}
            showOrderConfirm={showOrderConfirm}
            setShowOrderConfirm={setShowOrderConfirm}
            handleButtonMoveClick={handleButtonMoveClick}
            cardDetails={cardDetails}
            sourceLane={sourceLane}
            showOrderGroupConfirm={showOrderGroupConfirm}
            setShowOrderGroupConfirm={setShowOrderGroupConfirm}
            reloadDashboardData={requestUpdatedDashboardData}
        />
    )
}
