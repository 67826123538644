import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io'
import { MdErrorOutline } from 'react-icons/md'

import OrderCard from '../dashboard/OrderCard'
import BasicOrderGroupIcon from '../assets/customIcons/OrderGroupIcon'
import { useDashboardContext } from '../context/DashboardContext'
import Colors from '../commons/Colors'
import * as DateUtils from '../commons/DateUtils'
import { getUserPreferences, keyStorage } from '../commons/LocalStorage'

const DragController = {
    cardClicked: false,

    setCardClicked() {
        DragController.cardClicked = true
        setTimeout(() => {
            DragController.cardClicked = false
        }, 100)
    },
}

const OrderGroupCard = props => {
    const {
        orders,
        orderGroups,
        handleOrderGroupModal,
        removeOrderGroupCardOnPreferences,
    } = useDashboardContext()
    const [collapsed, setCollapsed] = useState(
        props.collapsed !== undefined ? props.collapsed : false
    )
    const [color, setColor] = React.useState()
    const [borderColor, setBorderColor] = React.useState()

    const contentRef = useRef()
    const collapsibleRef = useRef()
    const collapsedRef = useRef()

    useEffect(() => {
        function getColor() {
            if (collapsed) return Colors.brownishGrey

            return props.laneId === 'EM_ESTOQUE' ? '#6f6f74' : '#f0f0f6'
        }

        function getBorderColor() {
            return props.isEditable ? '#333333' : '#c7c7cc'
        }

        setColor(getColor())
        setBorderColor(getBorderColor())
    }, [props, collapsed])

    const existsUrgentOrders = () => {
        return props.orders.some(order => order.isUrgent)
    }

    const countUrgentOrders = () => {
        return props.orders.reduce((total, order) => {
            if (order.isUrgent) {
                return total + 1
            }
            return total
        }, 0)
    }

    const getDeliveryDate = () => {
        const orderGroup = orderGroups.find(
            orderGroup => orderGroup._id === props._id
        )

        if (!orderGroup) return

        const ordersIds = orderGroup.orders.map(order => order._id)
        const ordersFromOrderGroup = orders.filter(
            order =>
                ordersIds.includes(order._id) || ordersIds.includes(order.gcpId)
        )

        ordersFromOrderGroup.sort(
            (o1, o2) =>
                new Date(o1.deliveryDate).getTime() -
                new Date(o2.deliveryDate).getTime()
        )

        return DateUtils.dateFormat(ordersFromOrderGroup[0].deliveryDate)
    }

    const toggleCollapse = () => {
        setCollapsed(collapsed => {
            if (!collapsed) collapseOrderGroup()
            else expandOrderGroup()

            return !collapsed
        })
    }

    const getCollapseIcon = () => {
        return collapsed ? (
            <IoMdArrowDropdown size={20} />
        ) : (
            <IoMdArrowDropup size={20} />
        )
    }
    const collapseOrderGroup = () => {
        contentRef.current.classList.add('content')
        contentRef.current.classList.remove('expanded-content')

        collapsibleRef.current.classList.add('invisible-collapsible')
        collapsibleRef.current.classList.remove('visible-collapsible')

        collapsedRef.current.classList.add('visible-collapsed')
        collapsedRef.current.classList.remove('invisible-collapsed')

        const orderGroupNumber = props.orderGroupNumber
        handleUserPreferencesForCard({ orderGroupNumber, collapsed: true })
    }

    const expandOrderGroup = () => {
        contentRef.current.classList.remove('content')
        contentRef.current.classList.add('expanded-content')

        collapsibleRef.current.classList.remove('invisible-collapsible')
        collapsibleRef.current.classList.add('visible-collapsible')

        collapsedRef.current.classList.remove('visible-collapsed')
        collapsedRef.current.classList.add('invisible-collapsed')

        const orderGroupNumber = props.orderGroupNumber
        removeOrderGroupCardOnPreferences(orderGroupNumber)
    }

    const handleUserPreferencesForCard = ({ orderGroupNumber, collapsed }) => {
        const userPreferences = getUserPreferences()
        const orderGroupOnPreferences =
            userPreferences[keyStorage.orderGroupCard]

        localStorage.setItem(
            keyStorage.userPreferences,
            JSON.stringify({
                ...userPreferences,
                [keyStorage.orderGroupCard]: {
                    ...orderGroupOnPreferences,
                    [orderGroupNumber]: { collapsed },
                },
            })
        )
    }

    useEffect(() => {
        const userPreferences = getUserPreferences()
        const orderGroupNumber = props.orderGroupNumber

        const orderGroupOnPreferences =
            userPreferences[keyStorage.orderGroupCard][orderGroupNumber]
        if (orderGroupOnPreferences) {
            collapseOrderGroup()
            setCollapsed(true)
        }
        // eslint-disable-next-line
    }, [props.orderGroupNumber])

    return (
        <Container
            borderColor={borderColor}
            onMouseMove={() => {
                if (DragController.cardClicked) {
                    !collapsed && toggleCollapse()
                }
            }}
            onMouseDown={DragController.setCardClicked}
        >
            <Content className="expanded-content" ref={contentRef}>
                <Header id="order-group-header" color={color}>
                    <Text>Carga agrupada</Text>
                    <ThreeColumnsContainer>
                        <Text>Nº {props.orderGroupNumber}</Text>
                        <OrderGroupIcon
                            onClick={() =>
                                handleOrderGroupModal(true, {
                                    _id: props._id,
                                    isEditable: props.isEditable,
                                })
                            }
                        />
                        <CollapseIconContainer
                            data-testid="collapse-icon"
                            onClick={toggleCollapse}
                        >
                            {getCollapseIcon()}
                        </CollapseIconContainer>
                    </ThreeColumnsContainer>
                </Header>

                <Collapsible
                    className="visible-collapsible"
                    ref={collapsibleRef}
                >
                    {props.orders.map((order, index, allOrders) => (
                        <React.Fragment key={order._id}>
                            <OrderCard isOrderGroup {...order} />
                            {index + 1 !== allOrders.length && <Spacer />}
                        </React.Fragment>
                    ))}
                </Collapsible>

                <Collapsed className="invisible-collapsed" ref={collapsedRef}>
                    <OrderGroupResume>
                        <OrdersQuantity>
                            {props.orders.length === 1
                                ? '1 pedido'
                                : `${props.orders.length} pedidos`}
                        </OrdersQuantity>
                        <DeliveryDate>{getDeliveryDate()}</DeliveryDate>
                    </OrderGroupResume>

                    {existsUrgentOrders() && (
                        <UrgentContainer>
                            <UrgentIcon />
                            <UrgentText>
                                {countUrgentOrders() === 1
                                    ? '1 pedido urgente'
                                    : `${countUrgentOrders()} pedidos urgentes`}
                            </UrgentText>
                        </UrgentContainer>
                    )}
                </Collapsed>
            </Content>
        </Container>
    )
}

const Container = styled.div`
    margin-top: 10px;
    border-radius: 8px;
    border: 4px solid ${props => props.borderColor};

    .content {
        background-color: white;
    }

    .expanded-content {
        background-color: transparent;
    }

    .visible-collapsible {
        max-height: 9999px;
    }

    .invisible-collapsible {
        max-height: 0;
    }

    .visible-collapsed {
        max-height: 9999px;
    }

    .invisible-collapsed {
        max-height: 0;
    }
`

const Content = styled.div`
    border-radius: 4px;
    overflow: hidden;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3px;
    padding-left: 4px;
    padding-right: 4px;
    color: ${props => props.color};
`

const ThreeColumnsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 8px;
`

const Text = styled.span`
    font-family: Roboto;
    font-size: 12px;
    font-style: italic;
    line-height: 1.67;
    letter-spacing: 0.21px;
`

const CollapseIconContainer = styled.div`
    cursor: pointer;
`

const Collapsible = styled.div`
    transition: max-height 0.3s;
    overflow: hidden;
`

const Spacer = styled.div`
    height: 10px;
`

const OrderGroupIcon = styled(BasicOrderGroupIcon)`
    cursor: pointer;
`

const Collapsed = styled.div``

const OrdersQuantity = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.black};
`

const OrderGroupResume = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 4px;
    padding-right: 4px;
`

const UrgentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    margin-top: 4px;
    background-color: #9f1111;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
`

const DeliveryDate = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.strawberry};
`

const UrgentIcon = styled(MdErrorOutline)`
    color: #f0f0f6;
    margin-right: 4px;
    font-size: 18px;
`

const UrgentText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    color: #f0f0f6;
`

export default OrderGroupCard
